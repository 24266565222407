<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="security" v-loading="loading">
			<div class="item-wrap" v-if="type == 'all'">
				<div class="item">
					<div class="item-content">
						<i class="iconfont iconxiugaidenglumima"></i>
						<div class="name-wrap">
							<div class="name">Login password</div>
							<div class="content">There is a risk of theft of your internet account. It is recommended that you change your password regularly to protect your account security</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('password')">Edit</el-button></div>
				</div>
				<!-- <div class="item">
					<div class="item-content">
						<i class="iconfont iconyouxiang1"></i>
						<div class="name-wrap">
							<div class="name">邮箱绑定</div>
							<div class="content">验证后，可用于快速找回登录密码及支付密码</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('email')">修改</el-button></div>
				</div> -->
				<div class="item">
					<div class="item-content">
						<i class="iconfont iconshoujiyanzheng"></i>
						<div class="name-wrap">
							<div class="name">Mobile phone verification</div>
							<div class="content">After verification, it can be used to quickly retrieve the login password and payment password and receive reminders of account balance changes</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('tell')">Edit</el-button></div>
				</div>
			<!-- 	<div class="item">
					<div class="item-content">
						<i class="el-icon-lock"></i>
						<div class="name-wrap">
							<div class="name">支付密码</div>
							<div class="content">互联网支付存在被盗风险，建议您定期更改支付密码以保护账户安全</div>
						</div>
					</div>
					<div class="btn"><el-button type="primary" size="medium" @click="edit('payPassWord')">修改</el-button></div>
				</div> -->
			</div>
			<div class="edit" v-if="type == 'password'">
				<div class="title">Modify login password</div>
				<div v-if="memberInfo.password">
					<div class="pass-form">
						<el-form :model="passWordForm" :rules="passWordRules" ref="passWordRef" label-width="100px">
							<el-form-item label="Old Password" prop="oldPass"><el-input type="password" placeholder="Current Password" v-model="passWordForm.oldPass"></el-input></el-form-item>
							<el-form-item label="New Password" prop="pass"><el-input type="password" placeholder="New Password" v-model="passWordForm.pass"></el-input></el-form-item>
							<el-form-item label="Sure Password" prop="checkPass">
								<el-input type="password" placeholder="Sure Password" v-model="passWordForm.checkPass"></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="btn">
						<el-button type="primary" @click="save">Save</el-button>
						<el-button @click="type = 'all'">Cancel</el-button>
					</div>
				</div>
				<div v-else class="tell-pass">
					<el-form :model="tellPassForm" :rules="tellPassRules" ref="tellPassRef" label-width="100px">
						<el-form-item label="Captcha" prop="code">
							<el-input placeholder="Please enter the verification code" maxlength="4" v-model="tellPassForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="Dynamic code" prop="tellPassDynacode">
							<el-input placeholder="Please enter dynamic code" v-model="tellPassForm.tellPassDynacode">
								<template slot="append">
									<el-button type="primary" @click="getTellPassCode">{{ tellPassForm.tellPassCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
						<p class="tell-code">Click "get dynamic code" and your bound mobile phone number will be displayed{{ memberInfo.mobile | mobile }}Send verification code</p>
						<el-form-item label="New password" prop="pass"><el-input type="password" placeholder="New password" v-model="tellPassForm.pass"></el-input></el-form-item>
						<el-form-item label="Confirm password" prop="checkPass"><el-input type="password" placeholder="Please confirm the new password" v-model="tellPassForm.checkPass"></el-input></el-form-item>
					</el-form>
					<div class="btn">
						<el-button type="primary" @click="tellPassSave">Save</el-button>
						<el-button @click="type = 'all'">Cancel</el-button>
					</div>
				</div>
			</div>
			<div class="edit" v-if="type == 'email'">
				<div class="title">Bind mailbox</div>
				<div class="pass-form">
					<el-form :model="emailForm" :rules="emailRules" ref="emailRef" label-width="100px">
						<el-form-item label="Current Email" prop="email" v-if="emailForm.currEmail">
							<p>{{ emailForm.currEmail }}</p>
						</el-form-item>
						<el-form-item label="Email" prop="email"><el-input type="email" placeholder="Please enter email address" v-model="emailForm.email"></el-input></el-form-item>
						<el-form-item label="Verification Code" prop="code">
							<el-input placeholder="Please enter the verification code" maxlength="4" v-model="emailForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="Dynamic code" prop="emailDynacode">
							<el-input placeholder="Please enter dynamic code" v-model="emailForm.emailDynacode">
								<template slot="append">
									<el-button type="primary" @click="getEmailCode">{{ emailForm.emailCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindEmail">Save</el-button>
					<el-button @click="type = 'all'">Cancel</el-button>
				</div>
			</div>
			<div class="edit" v-if="type == 'tell'">
				<div class="title">Bind mobile phone number</div>
				<div class="pass-form">
					<el-form :model="tellForm" :rules="tellRules" ref="tellRef" label-width="100px">
						<el-form-item label="Current mobile number" prop="email" v-if="tellForm.currTell">
							<p>{{ tellForm.currTell }}</p>
						</el-form-item>
						<el-form-item label="cell-phone number" prop="tell"><el-input type="tell" placeholder="Please enter your mobile phone number" v-model="tellForm.tell"></el-input></el-form-item>
						<el-form-item label="Verification Code" prop="code">
							<el-input placeholder="Please enter the verification code" maxlength="4" v-model="tellForm.code">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getcaptcha" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="Dynamic code" prop="tellDynacode">
							<el-input placeholder="Please enter dynamic code" v-model="tellForm.tellDynacode">
								<template slot="append">
									<el-button type="primary" @click="gettellCode">{{ tellForm.tellCodeText }}</el-button>
								</template>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindtell">Save</el-button>
					<el-button @click="type = 'all'">Cancel</el-button>
				</div>
			</div>
			<div class="edit-pay" v-if="type == 'payPassWord'">
				<div class="title">Bind payment password</div>
				<div class="container">
					<div class="name" v-if="step != 0">Please enter a 6-digit payment password. It is recommended not to use repeated or consecutive numbers</div>
					<div class="name" v-else-if="isSend">Verification code sent to {{ tellForm.currTell | mobile }}</div>
					<div class="password-wrap">
						<el-input :maxlength="step == 0 ? 4 : 6" @change="input" ref="input" :auto-focus="true" v-model="payInput" :placeholder="palceText" v-if="step == 0"></el-input>
						<el-input
							:maxlength="step == 0 ? 4 : 6"
							@change="input"
							ref="input"
							:auto-focus="true"
							v-model="payInput"
							type="password"
							:placeholder="palceText"
							v-else
						></el-input>
						<div v-show="step == 0" class="dynacode" @click="sendMobileCode">{{ payCodeText }}</div>
					</div>
				</div>
				<div class="btn">
					<el-button type="primary" @click="bindPayPwd" :disabled="isClick">Save</el-button>
					<el-button @click="type = 'all'">Cancel</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import security from './security';
export default {
	name: 'security',
	mixins: [security]
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.security {
	background: #ffffff;
	.el-form {
		margin: 0 30px;
		.captcha {
			vertical-align: top;
			max-width: inherit;
			width: 74px;
			max-height: 38px;
			line-height: 38px;
			cursor: pointer;
		}
	}
	.item {
		border-bottom: 1px solid #f1f1ff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		.item-content {
			display: flex;
			align-items: center;
			i {
				font-size: 40px;
			}
			.name-wrap {
				margin-left: 20px;
			}
		}
	}
	.edit {
		padding: 20px;
		.title {
			padding-bottom: 5px;
			font-size: $ns-font-size-base;
			border-bottom: 1px solid #f1f1f1;
		}
		.pass-form {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			.el-form {
				width: 500px;
			}
		}
		.btn {
			display: flex;
			justify-content: center;
		}
	}
	.tell-pass {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.el-form {
			width: 500px;
		}
		.tell-code {
			margin-left: 66px;
			margin-bottom: 20px;
		}
	}
	.edit-pay {
		padding: 20px;
		text-align: center;
		.title {
			font-size: $ns-font-size-base;
			border-bottom: 1px solid #f1f1f1;
			text-align: left;
			padding-bottom: 5px;
		}
		.dynacode {
			cursor: pointer;
			text-align: right;
			margin-right: 329px;
			color: $base-color;
			margin-bottom: 20px;
		}
		.el-input {
			width: 300px;
			margin-top: 20px;
		}
		.btn {
			margin-top: 20px;
		}
		.name {
			margin-top: 10px;
		}
	}
}
</style>
